import React from "react";

export default function Calendar() {
  // let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(timeZone);

   return (
     <div
       style={{
         fontFamily: "Arial, Helvetica, sans-serif",
         width: "600px",
         display: "flex",
         justifyContent: "center",
       }}
     >
       <iframe
         title="Class Calendar"
         src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=YmVua29mc3RhZmZAZ21haWwuY29t&amp;color=%23039BE5&amp;showNav=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;mode=AGENDA"
         style={{
           marginTop: "30px",
           width: "400px",
           height: "600px",
         }}
       ></iframe>
     </div>
   );
}
