import React from "react"
import { Button } from "react-bootstrap"


const AllAccess = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        width: "600px",
        display: "inline",
        justifyContent: "center",
      }}
    >
      {/* <br />
      <br />
      <iframe
        title="David Benkof Video"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/PE9GPADTkmI?si=YEnCuy4iLdt8yvRb?&autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> */}
      <br />
      <br />

<h2 style={{
  display: "flex",
  justifyContent: "center",
}}><strong>Broadway Maven ALL-ACCESS PASS (July-December 2024)</strong></h2>
<p>Exciting news: The Broadway Maven has unveiled an <strong>exciting slate of classes</strong> for the second half of 2024, with <strong>approximately 50 classes</strong> on the calendar for July through December.</p>

<p>You can attend them all <strong>without registering or paying individually</strong> by purchasing a 2024 ALL-ACCESS Pass. Join this vibrant community of Broadway enthusiasts and get:</p>
<ul>
  <li>automatic registration for all free and paid classes</li>
  <li>fee waived for all tuition-based classes</li>
  <li>Premium subscription to MARQUEE: The Broadway Maven’s Weekly Blast (a $30 value)</li>
  <li>Full access to the Archive</li>
  <li>Two BONUS classes only for Passholders</li>
  <li>Periodic drawings for tickets to FREE on- and off-Broadway shows, including at least one opening</li>
</ul>


<p>Upcoming curriculum (subject to change):</p>

<strong><i></i></strong>
<ul>
<li><strong>July: </strong>Sondheim classes (<strong><i>Gypsy</i></strong> and <strong><i>Into the Woods</i></strong>)</li>
<li><strong>August</strong>: Rodgers & Hammerstein series (<strong><i>Oklahoma!</i></strong>, <strong><i>Carousel</i></strong>, <strong><i>South Pacific</i></strong>,<strong><i>The Sound of Music</i></strong>)</li>
<li><strong>August</strong>: Stephen Schwartz series (<strong><i>Godspell</i></strong>, <strong><i>Pippin</i></strong>, <strong><i>Wicked</i></strong>, lesser-known Schwartz)</li>
<li><strong>September</strong>: Jewish Broadway series (Introduction to Jewish Broadway, <strong><i></i></strong>Fiddler on the Roof, <strong><i>Parade</i></strong> and <strong><i>Ragtime</i></strong>, Barbra Streisand Musicals, 21st century Jewish Broadway)</li>
<li><strong>September</strong>: Sondheim Academy series (with co-instructors Mateo Chavez Lewis and Gail Leondar-Wright)</li>
<li><strong>November</strong>: Early Broadway series (<strong><i></i></strong>Show Boat, Cole Porter on Broadway, Gershwin on Broadway)</li>
<li><strong>November</strong>: Lyrics and Lyricists series (Hart and Hammerstein; Loesser and Lerner; Ashman and Ahrens; and Sondheim)</li>
<li><strong>December</strong>: <strong><i>Cabaret</i></strong>, <strong><i>Chicago</i></strong>, and <strong><i>A Chorus Line</i></strong></li>
<li><strong>December</strong>: “Sondheim Bit by Bit” (lecture series)</li>
</ul>

<p>The cost for individual classes is $12 each, the cost for individual series is $36 each, but an <strong>ALL-ACCESS Pass for July-December is only $99.</strong></p>

<p>Don't miss out on this extraordinary value! Passes on sale ONLY through August 31, 2024.</p>

<p><strong>Specific class offerings subject to change. No partial registrations, no refunds, no rebates, no exceptions.</strong></p>


<br />
<br />

<Button
            style={{ backgroundColor: "red",
            borderRadius: "1em", }}
            variant="danger"
            size="large"
          >
            <a
              style={{
                fontSize: "36px",
                textDecoration: "none",
                color: "white",
               
              
              }}
              rel="noreferrer"
              target="_blank"
              href="https://www.eventbrite.com/e/broadway-maven-all-access-pass-july-december-2024-tickets-936486445167?aff=oddtdtcreator"
            >
              Sign up for an ALL-ACCESS Pass
            </a>
          </Button>
    </div>
  )
}

export default AllAccess
