import React from "react"

export default function StudentSeminar() {
  return (
    <div
      style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        width: "600px",
        display: "inline",
        justifyContent: "center",
      }}
    >
      <p>
        <strong>Broadway Maven Scholars Student Seminar</strong>
      </p>
      <p>
        <strong>Information and Application</strong>
      </p>
      <p>&nbsp;</p>
      <p>Thank you for your interest in the Broadway Maven Scholars Program!</p>
      <p>&nbsp;</p>
      <p>
        Below you&rsquo;ll find details and the application form for this
        intensive workshop on Broadway history and music that comes with a $500
        stipend, a certificate of achievement, and four invitations to Broadway
        shows at The Hayes Theatre. Full-time students ages 16-26 are invited to
        apply. Applications will be considered on a rolling basis, so it&rsquo;s
        a good idea to apply soon.
      </p>
      <p>&nbsp;</p>
      <p>
        Every Sunday in September and October 2024 from Noon pm to 1:30 pm ET, a
        select group of full-time high school, college, and university students
        will explore the last century of Broadway musicals as part of an
        intensive paid seminar.
      </p>
      <p>&nbsp;</p>
      <p>
        Each of the nine meetings will include a 30-minute lecture covering five
        musicals, their content, and how they were shaped by and helped shape
        American history. We&rsquo;ll also have discussions, share
        presentations, hear from guest speakers, and watch essential clips from
        the Broadway canon.
      </p>
      <p>&nbsp;</p>
      <p>
        Students will also attend select Broadway Maven classes in September:
        &ldquo;Jewish Broadway&rdquo; (Mondays at Noon) and &ldquo;Sondheim
        Academy&rdquo; (Tuesdays at Noon).
      </p>
      <p>&nbsp;</p>
      <p>To complete the program, students will:</p>
      <p>&nbsp;</p>
      <ul>
        <li>Miss no more than one of the nine Sunday sessions;</li>
        <li>Attend at least four of the Monday or Tuesday sessions;</li>
        <li>Participate in class discussions and presentations;</li>
        <li>Watch video homework assignments (15-30 minutes a week); and</li>
        <li>Create a Final Project, video or written, on a Broadway topic.</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Applications are considered on a rolling basis, so earlier applications
        are more likely to be accepted, but there will be a waiting list if
        necessary and no student will be denied consideration. Applications will
        be accepted as late as August 30.
      </p>
      <p>&nbsp;</p>
      <p>
        If you have questions, you may reach out to{" "}
        <a href="mailto:DavidBenkof@gmail.com">DavidBenkof@gmail.com</a>. To
        apply, E-mail the completed application (below) to{" "}
        <a href="mailto:BenkofStaff@gmail.com">BenkofStaff@gmail.com</a>.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Application: Broadway Maven Scholars</strong>
      </p>
      <p>Name:</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        E-mail
        address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Phone number:
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        Date of Birth:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Age:
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        City of Residence:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; School you attend
        full-time:
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>Social media/website (optional):</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Question one:</strong> In 10 years, what involvement in the
        Broadway world do you anticipate/hope for, and why? (Actor, audience
        member, director, patron, dancer, etc.) 100-150 words
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Question two:</strong> What is an important problem facing
        Broadway? What are two ways you would propose to address that problem?
        200-250 words
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Question three:</strong> Why are you interested in the Broadway
        Maven Scholars program? Why do you think your application should be
        chosen? What do you believe you could provide your fellow participants?
        200-250 words
      </p>
    </div>
  )
}
