import React from "react";
import Calendar from '../Calendar/Calendar'
import { Link } from "react-router-dom";



export default function Home() {
  return (
    <div>
      <br />
      <Link to="/all-access">
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "underline",
                  hover: "hand",
                  color: "#7756e3",
                }}
              >Broadway Maven ALL-ACCESS Pass</div>
            </Link>

            <br />
            <Link to="/studentseminar">
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "underline",
                  hover: "hand",
                  color: "#7756e3",
                }}
              >Apply for the Student Seminar</div>
            </Link>
            <br />
            <br />
      <Calendar />
      <br />
      <br />

      <iframe
        title="David Benkof Video"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/G8S2NoKZMQY?si=IF6nFpWvM2OnqIQJ?autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <br />
      <br />
    </div>
  );
}



