import React from "react"
import david from "./david.jpg"
import aleks from "./aleks.jpg"
import mateo from "./mateo.jpg"
import max from "./max.jpg"
import gail from "./gail.jpg"

const AboutUs = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        width: "600px",
        display: "inline",
        justifyContent: "center",
      }}
    >
      <h4>The Broadway Maven</h4>
      <img src={david} alt="David" />
      <p>
        <strong>David Benkof</strong> has been teaching Broadway appreciation
        and Jewish Culture 101 online for more than four years. The author of
        two books, he holds a master's degree from Stanford in history and
        taught "in-person" high school and adult education for many years. With
        his online courses, David aims to help students see the third time
        things they did not notice the first. His love for Broadway is triply
        expressed: his Weekly Blast, his classes, and his YouTube channel. He
        lives in Jerusalem.
      </p>

      <h3>Co-hosts</h3>
      <img src={mateo} alt="Mateo" />
      <p>
        <strong>Mateo Chavez Lewis</strong> Mateo Chavez Lewis is a Toronto and
        New York-based musical theatre writer/composer. His body of work
        includes Tommy Rhodes, The Yellow Wallpaper, The Accountant, Quarantine
        Songs, and Boys Don’t Cry (Listen to demos and download sheet music at{" "}
        <a
          href="https://www.mateochavezlewis.com/"
          rel="noreferrer"
          target="_blank"
        >
          www.mateochavezlewis.com
        </a>
        ). Mateo is a member of the Musical Creators Institute at the
        Dramatist’s Guild in NYC. He also runs the Music Theatre Theory YouTube
        Channel, where he analyzes his favourite songs from the music theatre
        canon on a weekly basis.
      </p>
      <img src={aleks} alt="Aleks" />
      <p>
        <strong>Aleks Salkin</strong> is a born and bred Jewish Nebraskan. When
        not co-hosting The Broadway Maven, he is a full-time strength and
        fitness coach who also runs an online fitness info publishing business
        and teaches strength training workshops around the world. He is an avid
        language learner, including speaking fluent Hebrew and Italian.
      </p>

      <img src={max} alt="Max" />

      <p>
        <strong>Max Clark</strong> is a second-year college theatre student and
        has loved participating in live theatre his whole life. He holds a
        certificate from The Broadway Maven's Introduction to Broadway workshop
        and is thrilled to now be working with this wonderful crew.
      </p>

      <h3>Adjunct Faculty</h3>

      <img src={gail} alt="Gail" />
      <p>
        <strong>Gail Leondar-Wright</strong> spends her time studying and
        teaching about the works of Stephen Sondheim. She facilitates the online
        national “Sondheim Study Group,” and has taught her classes for a number
        of lifelong learning communities connected with universities and
        religious congregations. In recent months, she supported the Pasadena
        Playhouse and The Lyric Stage Company of Boston productions of Sondheim
        musicals with virtual lectures, was a guest instructor for San Diego
        State University MFA in Musical Theatre program, and was a panelist at
        the 2023 BroadwayCon session: “I Know Things Now: Remembering Stephen
        Sondheim.” Gail holds an MA in Performance Studies from New York
        University and a BA in Drama from The University of California at
        Berkeley.
      </p>
    </div>
  )
}

export default AboutUs
