import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { BrowserRouter, Link, Route, Switch } from "react-router-dom"
import "./App.css"
import Calendar from "./Calendar/Calendar"
import Home from "./Home/Home"
import Menu from "./Menu/Menu"
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy"
import WeeklyBlast from "./WeeklyBlast/WeeklyBlast"
import AboutUs from "./AboutUs/AboutUs"
import AllAccess from "./AllAccess/AllAccess"
import StudentSeminar from "./StudentSeminar/StudentSeminar"

const App = () => {
  return (
    <BrowserRouter>
      <Container>
        <Row style={{}} className="App">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/" style={{textDecoration: "none",
    color: "#473c65"
            }}>
              <div>
                <h1>The Broadway Maven</h1>
              </div>
            </Link>
          </Col>
          <Col
            style={{
              marginRight: "200px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Menu />
          </Col>
          <br />
          <Col
            style={{
              paddingBottom: 20,
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          ></Col>

          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Switch>
              <Route exact path="/calendar" component={Calendar} />
              <Route exact path="/privacypolicy" component={PrivacyPolicy} />
              <Route exact path="/weeklyblast" component={WeeklyBlast} />
              <Route exact path="/" component={Home} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/all-access" component={AllAccess} />
              <Route exact path="/studentseminar" component={StudentSeminar} />
            </Switch>
          </Col>
          <Col
            style={{
              paddingBottom: 50,
              marginTop: 200,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              style={{ fontSize: "11px", color: "##7756e3" }}
              to="/privacypolicy"
            >
              Privacy Policy
            </Link>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  )
}

export default App
