import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import "./Menu.css"
import logo from "./ytlogo.png"
import menuIcon from "./menu.png"

const Menu = (props) => {
  const [openMenu, setOpenMenu] = useState(false)
  const setClassNames = (num) => {
    const classArr = ["m-item"]
    if (openMenu) classArr.push(`open-${num}`)
    return classArr.join(" ")
  }

  const pushToRoute = (route) => {
    props.history.push(route)
    setOpenMenu(false)
  }

  return (
    <div className="Menu">
      <div className={"m-item m-logo"} onClick={() => setOpenMenu(!openMenu)}>
        Menu{" "}
        <img
          src={menuIcon}
          style={{ marginLeft: "10px" }}
          alt="YouTube"
          height="30dp"
          width="30pd"
        ></img>
      </div>
      <div className={setClassNames(1)} onClick={() => pushToRoute("/")}>
        Home
      </div>
      <div
        className={setClassNames(2)}
        onClick={() => pushToRoute("/about-us")}
      >
        About Us
      </div>
      <div
        className={setClassNames(3)}
        onClick={() => pushToRoute("/weeklyblast")}
      >
        MARQUEE
      </div>
      <a
        style={{
          textDecoration: "none",
          color: "white",
        }}
        className={setClassNames(4)}
        href="https://www.youtube.com/c/DavidBenkofTheBroadwayMaven"
        rel="noreferrer"
        target="_blank"
      >
        <img src={logo} alt="YouTube" height="30dp" width="150dp"></img>
      </a>

      <div
        className={setClassNames(5)}
        onClick={() => pushToRoute("/all-access")}
      >
        ALL-ACCESS Pass
      </div>

      <div
        className={setClassNames(6)}
        onClick={() => pushToRoute("/studentseminar")}
      >
        Student Seminar
      </div>
      
    </div>
  )
}

export default withRouter(Menu)
