import { Button } from "react-bootstrap"
import React from "react"

export default function WeeklyBlast() {
  return (
    <div
      style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        width: "600px",
        display: "inline",
        justifyContent: "center",
      }}
    >
      <h3>Why subscribe to MARQUEE?</h3>
      <h4>You love Broadway.</h4>

      <parseFloat>
        Subscribers to MARQUEE are musical theater fans who want to
        better understand Broadway shows. Every Thursday, all subscribers (paid
        and FREE) get an article offering a fresh perspective about a Broadway
        show. Recent examples:
      </parseFloat>

      <ul>
        <li>
          <a
            href="https://thebroadwaymaven.substack.com/p/finally-a-sondheim-textbook"
            rel="noreferrer"
            target="_blank"
            // style={{color: "darkblue"}}
          >
            Finally! A Sondheim textbook
          </a>
        </li>
        <li>
          <a
            href="https://thebroadwaymaven.substack.com/p/sacre-bleu-disneyland-paris-is-broadways"
            rel="noreferrer"
            target="_blank"
          >
            Sacre Bleu! Disneyland Paris is Broadway’s French Quarter
          </a>
        </li>
        <li>
          <a
            href="https://thebroadwaymaven.substack.com/p/sondheim-under-the-microscope"
            rel="noreferrer"
            target="_blank"
          >
            Sondheim under the microscope
          </a>
        </li>
        <li>
          <a href="https://thebroadwaymaven.substack.com/p/seth-rudetskys-new-book-is-fabulous" rel="noreferrer" target="_blank">
            Seth Rudetsky’s new book is FABulous
          </a>
        </li>
      </ul>

      <p>
        In addition, Premium subscribers (and everyone else once a month) get
        YouTube GEMS; student contributions; polls; reviews Broadway shows,
        books, albums, and more. For examples, check the first issue of the
        month at the archive.
      </p>

      <p>
        <br />
        <Button
          style={{ backgroundColor: "#7756e3", borderRadius: "10px" }}
          variant="outlined"
          size="large"
        >
          <a
            style={{
              fontSize: "30px",
              textDecoration: "none",
              color: "white",
            }}
            rel="noreferrer"
            target="_blank"
            href="https://thebroadwaymaven.substack.com/"
          >
            Subscribe to MARQUEE
          </a>
        </Button>
      </p>
    </div>
  )
}
